import "./AppUser.css";
import NavMenu from "../../Components/NavMenu/NavMenu";
import auNavOptions, {accNavOptions, logoutNav} from "./auNavOptions";
import img from "../../Assets/Images/userImage.png";
import User from "../../Services/User";
import { useNavigate } from "react-router-dom";
import RouteView from "../../Routes/RouteView";
import { useState, useEffect } from "react";
import { Get } from "../../Services/Server";
import Url from "../../Services/Url";

const AppUser=({ModalAction})=>{
    const[accNav, setNotify]=useState();
    const[auNav, setNav]=useState();
    const biodata=User.getBiodata();
    useEffect(()=>{
        Get(Url.countNotifications(biodata.id)).then(resp=>{
            accNavOptions.find(op=>op.path.localeCompare("Notifications/*")===0).notify=resp+"";    
            setNotify(accNavOptions);
        })
    },[])

    
    logoutNav.navImg=img;
    biodata&&(logoutNav.title=biodata.firstname + " "+ biodata.lastname);
    const navigate=useNavigate();
    const onOk=()=>{
        User.Logout();
        navigate("../Login");
    }
    logoutNav.confirm={
        option:{
            title:"Logging out?",
            text:"You are about to logout from Fieldbase Tracker app",
            exitText:"Exit",
            okText:"Continue",
            onOk:onOk
            },
        ModalAction
    }
    return(
        <div className="Engineer">
            {accNav&&
            <>
            <NavMenu accNav={accNav} logout={logoutNav}>{auNavOptions}</NavMenu>
            <RouteView ModalAction={ModalAction} emptyRoute={auNavOptions[0]}>{[...auNavOptions, ...accNavOptions]}</RouteView>
            </>
            }
        </div>
    )
}
export default AppUser;