import React from "react";

import Button from "../../../Components/Button/Button";
import Svg from "../../../Components/Svg/Svg";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import SignContract from "../../../Modals/ClienContract/SignContract";
import css from "./ViewContract.module.css";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ContractDocument from "../ContractDocument/ContractDocument";
import { useState } from "react";
import { Error, Post } from "../../../Services/Server";
import Url from "../../../Services/Url";
import { toast } from "react-toastify";

const ViewContract = ({ ModalAction, job }) => {
	const { initial } = job;
	const {
		billingInformation,
		subscriberInformation,
		clauses,
		contactsInformationList,
	} = initial.contract;

	const contact = initial.contact;
	const [sign, setSign] = useState(initial.contract.contractSign);
	const handleSignContract = () => {
		const Options = {
			title: "Approve Contract",
			element: SignContract,
			closeModal: handleCloseModal,
			firstname: job.firstname,
			lastname: job.lastname,
		};
		ModalAction.openModal(Options, "Approve Contract");
	};
	const date = new Date(sign?.time);
	const handleCloseModal = (data) => {
		ModalAction.closeModal();
		setSign(data);
	};
	const downloadPdf = async () => {
		if (sign && sign.data) {
			const blob = await pdf(
				<ContractDocument job={job} sign={sign} />
			).toBlob();
			saveAs(blob, "Fieldbase-Contract.pdf");
		} else {
			toast("Please sign the contract before downloading.");
		}
	};

	const handleSubmit = () => {
		Post(Url.client.signContract, { ...sign, initialId: initial.id })
			.then((resp) => {
				toast(
					"Contract Signed, click the download button to get a copy of your contract"
				);
				setSign({ ...resp });
			})
			.catch((err) => {
				console.log(err);

				Error(err);
			});
	};
	const ucFirst = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	return (
		<div className={css.ContractRightContainer}>
			{ModalAction ? (
				<div className={css.ContractRightTop}>
					<p className={css.Title}>Contract</p>
					<div>
						<Button
							className="Btn-Download"
							icon={SvgOptions.Download}
							onClick={downloadPdf}
						>
							<span style={{ marginLeft: "3px" }}>Download</span>
						</Button>
					</div>
				</div>
			) : (
				<div style={{ marginTop: "-25px" }}></div>
			)}
			<div>
				<div>
					<p className={css.SubTitle}>Service Provider's Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>Fieldbase Services Limited</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>sales@fbase.co.uk</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company Number: </p>
								<p>F10637893</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>07425-155-321</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Vat Number: </p>
								<p>313 7483 09</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Country: </p>
								<p>United Kingdom</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Contact First Name: </p>
								<p>Chinedu</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Province: </p>
								<p>Greater London</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Contact Last Name: </p>
								<p>Abanobi</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>City: </p>
								<p>London</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Role: </p>
								<p>Managing Director</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Postal Code: </p>
								<p>EC3V 3NG</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Also Designated as: </p>
								<p>-</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Address: </p>
								<p>International House, 36-38 Cornhill</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<p className={css.SubTitle}>Subscriber's Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Account Type: </p>
								<p>-</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>{job?.email || "-"}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>{ucFirst(subscriberInformation?.company?.name || "-")}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>{job?.phone || "-"}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Vessel Name: </p>
								<p>{subscriberInformation?.vessel?.name || "-"}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Country: </p>
								<p>{ucFirst(subscriberInformation?.country || "-")}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>First Name: </p>
								<p>{ucFirst(job?.firstname || "-")}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>State: </p>
								<p>{ucFirst(subscriberInformation?.state || "-")}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Last Name: </p>
								<p>{ucFirst(job?.lastname || "-")}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}> Country: </p>
								<p>{ucFirst(subscriberInformation?.country || "-")}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Role: </p>
								<p>{ucFirst(subscriberInformation?.role || "-")}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Postal Code: </p>
								<p>{subscriberInformation?.postalCode?.toUpperCase() || "-"}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Also Designated as: </p>
								<p>{ucFirst(subscriberInformation?.designation || "-")}</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<p className={css.SubTitle}>Billing Information</p>
					<div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Account Type: </p>
								<p>-</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Email: </p>
								<p>{billingInformation?.email || "-"}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Company: </p>
								<p>{ucFirst(billingInformation?.company?.name || "-")}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Phone: </p>
								<p>{billingInformation?.phone || "-"}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Postal: </p>
								<p>{billingInformation?.postalCode?.toUpperCase() || "-"}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Country: </p>
								<p>{ucFirst(billingInformation?.country || "-")}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>First Name: </p>
								<p>{ucFirst(billingInformation?.firstname || "-")}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>State: </p>
								<p>{ucFirst(billingInformation?.state || "-")}</p>
							</div>
						</div>
						<div className={css.InfoRow}>
							<div className={css.Items}>
								<p className={css.Key}>Last Name: </p>
								<p>{ucFirst(billingInformation?.lastname || "-")}</p>
							</div>
							<div className={css.Items}>
								<p className={css.Key}>Address: </p>
								<p>{ucFirst(billingInformation?.address || "-")}</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<p className={css.SubTitle}>Terms &amp; Condition</p>
					<div>
						<ol>
							{clauses.map((pr) => (
								<li key={pr.id}>
									<div className={css.ListTitles}>{pr.name}</div>
									<p
										className={css.ClauseDetails}
										dangerouslySetInnerHTML={{ __html: pr.details }}
									></p>
								</li>
							))}
						</ol>
					</div>
				</div>
				<div className={css.SignFoot}>
					<p className={css.SubTitle}>Signature</p>
					<div className={css.InfoRow}>
						<div>
							<p className={css.SignatureNames}>Chinedu Abanobi</p>
							<p>Managing Director at Fieldbase</p>
						</div>
						<div>
							<p className={css.SignatureNames}>
								{ucFirst(job.firstname) + " " + ucFirst(job.lastname)}
							</p>
							<p>{subscriberInformation?.designation}</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div>
							<div className={css.Signature}>
								<img src="/signature.png" alt="signature" />
							</div>
							<p className={css.Date}>Date: 12 Jan 2024</p>
						</div>
						{ModalAction && (
							<div style={{ width: "100%" }}>
								{sign && (
									<>
										<div className={css.Signature}>
											<img src={sign.data} alt="client signature" />
										</div>
										<div>
											{date.toDateString() + " " + date.toLocaleTimeString()}
										</div>
									</>
								)}
							</div>
						)}
					</div>
					{!sign?.id && (
						<div className={css.InfoRow}>
							<div></div>
							<div className={css.FootButtons}>
								{sign && (
									<Button
										className="Btn-Mid Active"
										onClick={handleSubmit}
										icon={SvgOptions.Check}
									>
										Submit Contract
									</Button>
								)}
								<Button
									className="Btn-Mid Active"
									onClick={handleSignContract}
									icon={SvgOptions.Edit2}
								>
									Sign Contract
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewContract;
