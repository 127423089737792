import Button from "../../../../../../Components/Button/Button";
import Form from "../../../../../../Components/Form/Form";
import SvgOptions from "../../../../../../Components/Svg/SvgOptions";
import AddContactFormOptions from "./AddContactFormOptions";
import AddSubscriberInformationFormOptions from "./AddSubscriberInformationFormOptions";
import AddBillingFormOptions from "./AddBillingFormOptions";
import css from "./BillingDetails.module.css";
import { forwardRef, useImperativeHandle, useState } from "react";
import Utils from "../../../../../../Services/Utills";

const BillingDetails = forwardRef(({ init }, ref) => {
	const [sales, setSales] = useState(init);
	const billingFormOptions = AddBillingFormOptions(sales);
	const subscriberInformationOptions =
		AddSubscriberInformationFormOptions(sales);
	const contactFormOptions = AddContactFormOptions(sales);
	useImperativeHandle(ref, () => ({
		data() {
			const subscriberInformation = Utils.FormObjectExtra(
				subscriberInformationOptions
			);
			const billingInformation = Utils.FormObjectExtra(billingFormOptions);
			const contactsInformationList = [
				Utils.FormObjectExtra(contactFormOptions),
			];
			return {
				id: init.id,
				subscriberInformation,
				billingInformation,
				contactsInformationList,
			};
		},
	}));
	const handeCopySubscriber = (e) => {
		if (!e.target.checked) return;
		if (!init.contract) init.contract = {};

		const subscriber = Utils.FormObject(subscriberInformationOptions);
		const contact = subscriber.contact;

		init.contract.contactsInformationList = [
			{ ...Utils.FormObject(contactFormOptions) },
		];
		init.contract.subscriberInformation = { ...subscriber };
		init.contract.billingInformation = {
			firstname: contact.firstname.toUpperCase(),
			lastname: contact.lastname.toUpperCase(),
			email: contact.email,
			phone: contact.phone,
			country: subscriber.country,
			state: subscriber.state,
			city: subscriber.city,
			address: subscriber.address,
			postalCode: subscriber.postalCode,
			role: subscriber.role,
			designation: subscriber.designation,
			company: subscriber.company,
		};
		setSales({ ...init });
	};

	const handleSubmitSubscriberInfo = (e) => {
		console.log(e);
	};
	const handleSubmitContactForm = (e) => {
		console.log(e);
	};
	const handleBillingForm = (e) => {
		console.log(e);
	};

	return (
		<div>
			<p className={css.Title}>Contract Information</p>
			<p className={css.FormTitle}>Subscriber's Information</p>
			<Form onsubmit={handleSubmitSubscriberInfo}>
				{subscriberInformationOptions}
			</Form>
			<p className={css.FormTitle}>
				Billing Information
				<span style={{ fontSize: "14px", color: "var(--blue)" }}>
					<input type="checkbox" onChange={handeCopySubscriber} /> Same as
					Subscriber
				</span>
			</p>
			<Form onsubmit={handleSubmitSubscriberInfo}>{billingFormOptions}</Form>
			<p className={css.FormTitle}>
				Additional Contacts
				<span>
					<Button className="Btn-Small Inverse" icon={SvgOptions.Add}>
						Add Contact
					</Button>
				</span>
			</p>
			<Form onsubmit={handleSubmitContactForm}>{contactFormOptions}</Form>
		</div>
	);
});

export default BillingDetails;
