import FormType from "../../../../../../Components/Form/FormType";
import Url from "../../../../../../Services/Url";

const TermDefinitionFormOptions = (init) => {
	const contract = init?.contract || {};
	const { contractTerms = {} } = contract;
	return {
		Fields: [
			{
				element: FormType.Select,
				required: true,
				label: "Contract Period (Months)",
				placeholder: "Select contract period",
				options: { url: Url.options.periods, id: "id", name: "name" },
				value: contractTerms?.contractPeriod,
				name: "contractPeriod",
			},
			{
				element: FormType.Select,
				required: true,
				label: "Warranty Period (Months)",
				placeholder: "Select Warranty Period",
				options: { url: Url.options.periods, id: "id", name: "name" },
				name: "warrantyPeriod",
				value: contractTerms?.warrantyPeriod,
			},
		],
	};
};

export default TermDefinitionFormOptions;
