import FormType from "../../Components/Form/FormType";
const FormOptions = {
	Fields: [
		{
			element: FormType.Input,
			required: true,
			label: "Email",
			name: "email",
			placeholder: "Enter operation's email",
		},
	],
	Buttons: [{ text: "Add", className: "Btn-Mid Active" }],
};
export default FormOptions;
