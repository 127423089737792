import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import styles from './style';
import fblogo from "../../Assets/Images/Logo2.png"
import Url from '../../Services/Url';
const PdfDoc = ({children, report, logo}) => {
const {initial, logs, debrief, taskEquipment}=report;
const task =children;
const leadEngr=task.taskEngineers.find(e=>e.lead).engineer;
const operation=task.ownerOperation?task.ownerOperation:task.taskOperations[0];
const chucks=(arr, size)=>Array.from({length: Math.ceil(arr.length/size)}, (v, i)=>arr.slice(i * size, i*size +size))
//console.log()
return (
    <Document style={styles.document}>
    <Page size="A4" style={styles.page}>
    <View style={styles.topSection}>
        <View style={styles.section}>
          {logo.data?
          <View><Image style={styles.logo} src={logo.data}></Image></View>:
          <View style={styles.centerH}><Text>{task.vessel?.company_name || task.company?.name}</Text></View>          
        }
        </View>
        <View style={styles.section2}>
          <View style={styles.centerH}><Image style={styles.logo} src={fblogo}></Image></View>
        </View>
      </View>

      <View style={styles.colGroup}>
        <View style={styles.col2}>
            <View style={styles.centerW}>
              <Text style={styles.strong}>Job Number</Text>          
            </View>
            <View style={styles.centerW}>
            <Text>{task.jobNumber}</Text>
            </View>
        </View>
        <View style={styles.col2}>
          <View style={styles.centerW}><Text style={styles.strong}>Service Location</Text></View>
          <View style={styles.centerW}><Text>{task.location}</Text></View>
        </View>
        {task.vessel? <View style={styles.col3}>
          <View style={styles.centerW}><Text style={styles.strong}>Vessel Name</Text></View>
          <View style={styles.centerW}><Text>{task.vessel?.name}</Text></View>
        </View> : ''}
      </View>

      <View style={styles.colGroup}>
      <View style={styles.col4}>
          <View style={styles.centerW}><Text style={styles.strong}>Service Authorized By</Text></View>
          <View style={styles.centerW}><Text>{operation.firstname +" "+operation.lastname}</Text></View>
        </View>
        <View style={styles.col4}>
          <View style={styles.centerW}><Text style={styles.strong}>Service Type</Text></View>
          {report.taskEquipment.map(e=>
            <View style={styles.centerW} key={e.id}><Text>
              {e.type.name + " of " + e.name}
            </Text></View>
            )}
        </View>
        <View style={styles.col}>
          <View style={styles.centerW}><Text style={styles.strong}>Service Engineer</Text></View>
          <View style={styles.centerW}><Text>{leadEngr.firstname +" "+ leadEngr.lastname}</Text></View>
        </View>
      </View>
      
      <View style={styles.colLineGroup}>
            <View style={styles.colLine1}>
              <Text>Started On: {(new Date(task.createdAt)).toLocaleString()}</Text>
            </View>
            <View style={styles.colLine2}>
              <Text>Finished On: {(new Date(task.updatedAt)).toLocaleString()}</Text>
            </View>
      </View>
      <View style={styles.colLineGroup2}></View>
      
      <View style={styles.colLineGroup}>
            <View style={styles.colLine}>
              <Text>Initial State</Text>
            </View>
      </View>
      <View style={styles.content2}>
        <View>
        <View><Text>{initial.details}</Text></View>
        <View style={styles.h10}></View>
          {chucks(initial.images, 2).map((ImgGroup, index)=>
          <View style={styles.ImgGroup} key={index}>
            {
              ImgGroup.map(img=>
                <View style={styles.colImage} key={img.id}>
                <Image style={styles.Img} src={Url.img.get(img.uploadId)}></Image>
                </View>                  
                )
            }
            </View>
            )}
        </View>
      </View>
      <View style={styles.colLineGroup2}></View>

      <View style={styles.colLineGroup}>
            <View style={styles.colLine}>
              <Text>Work Performed</Text>
            </View>
      </View>
      <View>
        {logs.map(log=>
          <View key={log.id} style={styles.content}>
            {log.jobDoneList.map((job, index)=>
              <View key={index} style={styles.list}>
                <View style={styles.ul}></View>
                <Text key={index}> {job.details}</Text>
              </View>
              )}
              <View style={styles.h10}></View>
              {chucks(log.images, 2).map((ImgGroup, index)=>
              <View key={index} style={styles.ImgGroup}>
                {ImgGroup.map(img=>
                  <View style={styles.colImage} key={img.id}>
                    <Image style={styles.Img} src={Url.img.get(img.uploadId)}></Image>
                  </View>
                  )}
              </View>
              )}
              <View style={styles.h10}></View>
              <Text>{log.observation}</Text>
          </View>
        )
        }
      </View>

      <View style={styles.colLineGroup2}></View>

      <View style={styles.colLineGroup}>
            <View style={styles.colLine}>
              <Text>Debrief</Text>
            </View>
      </View>
      <View style={styles.content}>
        <View><Text style={styles.text12}>Result</Text></View>
         <Text>{debrief.result}</Text>
         <View style={styles.h10}></View>

         <View><Text style={styles.text12}>Note</Text></View>
         <Text>{debrief.notes}</Text>
         <View style={styles.h10}></View>

        <View><Text style={styles.text12}>Part Replaced</Text></View>
        {debrief.parts.map((part, index)=>
              <Text key={index}>{index+1}. {part.name}</Text>
              )}

      </View>
      <View style={styles.colLineGroup2}></View>

      <View style={styles.colGroup}>
        <View style={styles.col}>
          <Text style={styles.strong}>Work Approved By</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.strong}>Position</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.strong}>Sign/Stamp</Text>
        </View>
      </View>


    </Page>
  </Document>
    )
}

export default PdfDoc