import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
	page: {
		padding: 40,
		fontSize: 10,
		lineHeight: 1.6,
	},
	infoRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	items: {
		flexDirection: "row",
		alignItems: "center",
		width: "48%",
	},
	key: {
		marginRight: 5,
		fontWeight: "900",
		fontSize: 9,
		width: 85,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	logo: { width: 90, height: 20 },
	contractTitle: {
		fontSize: 14,
		fontWeight: "bold",
		textAlign: "center",
		marginVertical: 10,
	},
	address: {
		textAlign: "right",
		fontSize: 9,
		color: "#555",
	},
	section: {
		marginVertical: 12,
		paddingBottom: 8,
		borderBottomWidth: 0.5,
		borderBottomColor: "#ddd",
	},
	sectionTitle: {
		fontSize: 12,
		fontWeight: "bold",
		marginBottom: 18,
	},
	text: { flex: 1, fontSize: 9 },
	text2: {
		fontSize: 9,
	},
	clauseTitle: {
		fontSize: 11,
		textDecoration: "underline",
		marginBottom: 6,
	},
	clause: {
		marginBottom: 10,
		textAlign: "justify",
	},
	infoGroup: {
		flexDirection: "column",
		// flexWrap: 'wrap',
	},
	fullWidthText: {
		width: "100%",
		marginBottom: 5,
	},
	signatureSection: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	signatureImage: { width: 100, height: 40, marginVertical: 5 },
	dateText: { fontSize: 10, color: "#555" },
});

export default styles;
