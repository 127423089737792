import SvgOptions from "../../Components/Svg/SvgOptions";
import User from "../../Services/User";
import ApprovedExpenses from "./ApprovedExpenses/ApprovedExpenses";
import EnterpriseApprovedExpenses from "../Enterprise/ApprovedExpenses/ApprovedExpenses";
import Companies from "./Companies/Companies";
import EnterpriseCompanies from "../Enterprise/Companies/Companies";
import ContractTemplate from "./ContractTemplate/ContractTemplate";
import EnterpriseContractTemplate from "../Enterprise/ContractTemplate/ContractTemplate";
import Dashboard from "./Dashboard/Dashboard";
import EnterpriseDashboard from "../Enterprise/Dashboard/Dashboard";
import Engineers from "./Engineers/Engineers";
import EnterpriseEngineers from "../Enterprise/Engineers/Engineers";
import Expenses from "./Expenses/Expenses";
import EnterpriseExpenses from "../Enterprise/Expenses/Expenses";
import Jobs from "./Jobs/Jobs";
import EnterpriseJobs from "../Enterprise/Jobs/Jobs";
import KnowledgeBase from "./KnowledgeBase/KnowledgeBase";
import ManageStaff from "./ManageStaff/ManageStaff";
import Notifications from "./Notifications/Notifications";
import OpEnterprise from "./OpEnterprise/Enterprise";
import Ops from "./Ops/Ops";
import EnterpriseOps from "../Enterprise/Ops/Ops";
import Settings from "./Settings/Settings";
import Tasks from "./Tasks/Tasks";
import EnterpriseTasks from "../Enterprise/Tasks/Tasks";

const btns = [
	{
		id: 1,
		text: "",
		icon: SvgOptions.Settings,
		className: "Inverse",
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
	{
		id: 2,
		text: "Add Task",
		icon: SvgOptions.Add,
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
	{
		id: 3,
		text: "Add Engineer",
		icon: SvgOptions.Add,
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
	{
		id: 4,
		text: "View Report",
		icon: SvgOptions.Receipt,
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
];

const pageControls = new Map([
	[
		"admin",
		new Map([
			["task", [btns[1], btns[0], btns[3]]],
			["engineer", [btns[2], btns[0]]],
		]),
	],
	[
		"enterprise-admin",
		new Map([
			["task", [btns[1], btns[0], btns[3]]],
			["engineer", [btns[2], btns[0]]],
		]),
	],
	[
		"operation",
		new Map([
			["task", [btns[1], btns[0]]],
			["engineer", [btns[2]]],
		]),
	],
	[
		"enterprise-ops",
		new Map([
			["task", [btns[1], btns[0]]],
			["engineer", [btns[2]]],
		]),
	],
	[
		"engineer",
		new Map([
			["task", []],
			["engineer", []],
		]),
	],
]);

const getControls = (page) => {
	const app_role = User.getRole();
	if (!app_role) return;
	if (pageControls.has(app_role.name)) {
		const app_ctrls = pageControls.get(app_role.name);
		return app_ctrls.get(page);
	}
};

const auNavOptions = [
	{
		element: Dashboard,
		path: "Dashboard",
		access: ["engineer", "admin", "account", "operation", "lawyer"],
		Protected: false,
		default: true,
		category: "maritime",
		icon: SvgOptions.Dashboard,
	},
	{
		element: EnterpriseDashboard,
		path: "EnterpriseDashboard",
		access: [
			"enterprise",
			"admin",
			"account",
			"enterprise-ops",
			"enterprise-admin",
			"lawyer",
		],
		Protected: false,
		default: true,
		title: "Dashboard",
		category: "enterprise",
		icon: SvgOptions.Dashboard,
	},
	{
		element: Tasks,
		path: "Tasks/*",
		access: ["engineer", "admin", "operation", "lawyer"],
		Protected: false,
		default: false,
		category: "maritime",
		icon: SvgOptions.Task,
		controls: () => getControls("task"),
	},
	{
		element: EnterpriseTasks,
		path: "EnterpriseTasks/*",
		access: [
			"enterprise",
			"admin",
			"enterprise-ops",
			"enterprise-admin",
			"lawyer",
		],
		Protected: false,
		default: false,
		title: "Tasks",
		category: "enterprise",
		icon: SvgOptions.Task,
		controls: () => getControls("task"),
	},
	{
		element: Jobs,
		path: "Jobs/*",
		access: ["bdm", "admin"],
		Protected: false,
		default: false,
		category: "maritime",
		icon: SvgOptions.Task,
		controls: () => getControls("task"),
	},
	{
		element: EnterpriseJobs,
		path: "EnterpriseJobs/*",
		access: ["bdm", "admin", "enterprise-admin"],
		Protected: false,
		default: false,
		category: "enterprise",
		icon: SvgOptions.Task,
		title: "Jobs",
		controls: () => getControls("task"),
	},
	{
		element: ApprovedExpenses,
		path: "Expense List/*",
		access: ["account", "admin"],
		Protected: false,
		default: true,
		category: "maritime",
		icon: SvgOptions.Receipt,
	},
	{
		element: EnterpriseApprovedExpenses,
		path: "Enterprise Expense List/*",
		access: ["account", "admin", "enterprise-admin"],
		Protected: false,
		default: true,
		category: "enterprise",
		title: "Expense List",
		icon: SvgOptions.Receipt,
	},
	{
		element: ManageStaff,
		path: "Users/*",
		access: ["admin"],
		Protected: false,
		category: "maritime",
		default: true,
		icon: SvgOptions.Receipt,
	},
	{
		element: ManageStaff,
		path: "Users/*",
		access: ["admin", "enterprise-admin"],
		Protected: false,
		category: "enterprise",
		default: true,
		icon: SvgOptions.Receipt,
	},
	{
		element: Engineers,
		path: "Engineers/*",
		access: ["engineer", "admin", "account", "operation"],
		Protected: false,
		default: true,
		category: "maritime",
		icon: SvgOptions.Engineer,
	},
	{
		element: EnterpriseEngineers,
		path: "EnterpriseEngineers/*",
		access: [
			"enterprise",
			"admin",
			"account",
			"enterprise-ops",
			"enterprise-admin",
		],
		Protected: false,
		default: true,
		title: "Engineers",
		category: "enterprise",
		icon: SvgOptions.Engineer,
	},
	{
		element: Ops,
		path: "Ops/*",
		access: ["engineer", "admin", "account", "operation"],
		Protected: false,
		default: true,
		category: "maritime",
		icon: SvgOptions.Ops,
	},
	{
		element: EnterpriseOps,
		path: "EnterpriseOps/*",
		access: [
			// "enterprise",
			"admin",
			"account",
			"enterprise-ops",
			"enterprise-admin",
		],
		Protected: false,
		default: true,
		title: "Ops",
		category: "enterprise",
		icon: SvgOptions.Ops,
	},
	{
		element: Companies,
		path: "Companies/*",
		access: ["engineer", "admin", "account", "operation", "bdm"],
		Protected: false,
		default: true,
		category: "maritime",
		icon: SvgOptions.Moving,
	},
	{
		element: EnterpriseCompanies,
		path: "EnterpriseCompanies/*",
		access: [
			// "enterprise",
			"admin",
			"account",
			"enterprise-ops",
			"enterprise-admin",
			"enterprise-bdm"
		],
		Protected: false,
		default: true,
		title: "Companies",
		category: "enterprise",
		icon: SvgOptions.Moving,
	},
	{
		element: Expenses,
		path: "Expenses/*",
		access: ["head"],
		Protected: false,
		default: true,
		title: "Expenses",
		category: "maritime",
		icon: SvgOptions.Receipt,
	},
	{
		element: EnterpriseExpenses,
		path: "EnterpriseExpenses/*",
		access: ["head"],
		Protected: false,
		default: true,
		title: "Expenses",
		category: "enterprise",
		icon: SvgOptions.Receipt,
	},
	{
		element: ContractTemplate,
		path: "contractTemplates/*",
		access: ["admin", "lawyer"],
		Protected: true,
		default: true,
		title: "Contract Templates",
		category: "maritime",
		icon: SvgOptions.Receipt,
	},
	{
		element: EnterpriseContractTemplate,
		path: "EnterprisecontractTemplates/*",
		access: ["admin", "enterprise-admin", "lawyer"],
		Protected: true,
		default: true,
		title: "Contract Templates",
		category: "enterprise",
		icon: SvgOptions.Receipt,
	},
	// {
	// 	element: OpEnterprise,
	// 	path: "Enterprise/*",
	// 	access: ["operation", "admin"],
	// 	Protected: false,
	// 	default: true,
	//   category:"maritime",
	// 	icon: SvgOptions.Receipt,
	// },
	{
		element: KnowledgeBase,
		path: "KnowledgeBase/*",
		access: ["engineer", "admin"],
		Protected: false,
		default: true,
		title: "Knowledge Base",
		category: "maritime",
		icon: SvgOptions.Receipt,
	},
	{
		element: KnowledgeBase,
		path: "Enterprise-KnowledgeBase/*",
		access: ["enterprise", "admin", "enterprise-admin"],
		Protected: false,
		default: true,
		title: "Knowledge Base",
		category: "enterprise",
		icon: SvgOptions.Receipt,
	},
];

const accNavOptions = [
	{
		element: Notifications,
		path: "Notifications/*",
		access: [
			"engineer",
			"admin",
			"account",
			"operation",
			"bdm",
			"enterprise",
			"enterprise-bdm",
			"enterprise-ops",
			"enterprise-admin",
			"lawyer",
		],
		Protected: false,
		default: true,
		notify: 3,
		icon: SvgOptions.Bell,
	},
	{
		element: Settings,
		path: "Settings",
		access: [
			"engineer",
			"admin",
			"account",
			"operation",
			"bdm",
			"enterprise",
			"enterprise-bdm",
			"enterprise-ops",
			"enterprise-admin",
			"lawyer",
		],
		Protected: false,
		default: true,
		icon: SvgOptions.Settings,
	},
];

const logoutNav = {
	element: Ops,
	path: "Logout",
	access: [
		"engineer",
		"admin",
		"account",
		"operation",
		"bdm",
		"enterprise",
		"enterprise-bdm",
		"enterprise-ops",
		"enterprise-admin",
		"lawyer",
	],
	Protected: false,
	default: true,
	title: "",
	subTitle: "Log out",
	navImg: "",
	confirm: {},
};

export { accNavOptions, logoutNav };
export default auNavOptions;
