export const MARITIME_ACCESS = [
	"admin",
	"bdm",
	"account",
	"lawyer",
	"engineer",
	"operation",
	"head",
];

export const ENTERPRISE_ACCESS = [
	"enterprise-admin",
	"admin",
	"enterprise-bdm",
	"account",
	"lawyer",
	"enterprise",
	"enterprise-ops",
	"head",
];

export const hasMaritimeAccess = (roles) =>
	MARITIME_ACCESS.some((role) => roles.includes(role));

export const hasEnterpriseAccess = (roles) =>
	ENTERPRISE_ACCESS.some((role) => roles.includes(role));
