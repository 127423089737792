import React from "react";
import RouteView from "../../Routes/RouteView";
import EpNavOption, { accNavOptions, logoutNav } from "./EpNavOption";
import NavMenu from "../../Components/NavMenu/NavMenu";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Get } from "../../Services/Server";
import User from "../../Services/User";
import img from "../../Assets/Images/userImage.png";
import Url from "../../Services/Url";

const Enterprise = ({ navoption, ModalAction }) => {
	const [accNav, setNotify] = useState();
	const [auNav, setNav] = useState();
	const biodata = User.getBiodata();
	useEffect(() => {
		Get(Url.countNotifications(biodata.id)).then((resp) => {
			accNavOptions.find(
				(op) => op.path.localeCompare("Notifications/*") === 0
			).notify = resp + "";
			setNotify(accNavOptions);
		});
	}, []);

	logoutNav.navImg = img;
	biodata && (logoutNav.title = biodata.firstname + " " + biodata.lastname);
	const navigate = useNavigate();
	const onOk = () => {
		User.Logout();
		navigate("../Login");
	};
	logoutNav.confirm = {
		option: {
			title: "Logging out?",
			text: "You are about to logout from Fieldbase Tracker app",
			exitText: "Exit",
			okText: "Continue",
			onOk: onOk,
		},
		ModalAction,
	};

	return (
		<div className="Engineer">
			{accNav && (
				<>
					<NavMenu accNav={accNav} logout={logoutNav}>
						{EpNavOption}
					</NavMenu>
					<RouteView ModalAction={ModalAction} emptyRoute={EpNavOption[0]}>
						{[...EpNavOption, ...accNavOptions]}
					</RouteView>
				</>
			)}
		</div>
	);
};

export default Enterprise;
