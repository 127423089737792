const ModalOptions = {
	confirm: {
		title: "Logging out?",
		text: "You are about to logout from Fieldbase Tracker app",
		exitText: "Exit",
		okText: "Continue",
		onOk: () => {},
	},
};
export default ModalOptions;
