import RoundedStatus from "../RoundedStatus/RoundedStatus";
import style from "./Milestone.module.css";
import { useState } from "react";
import User from "../../Services/User";
import { toast } from "react-toastify";
import { Error, Post } from "../../Services/Server";
import Url from "../../Services/Url";
import { ModalAction } from "../../Routes/NavRouter";
const Milestone = ({ children, onChange, updateUrl }) => {
	const { name, group } = children;
	const { executor, status } = group[0];
	const { count, options } = children;
	const roles = User.getRoles();
	const role = User.getRole();
	const hasRole = (roleName) =>
		roles.some((role) => role.name.toLowerCase() === roleName.toLowerCase());

	const send = (opList) => {
		opList
			.filter((op) => op.selected)
			.forEach((op) => {
				op.status = { id: role.id === 1 && children.approvalRequired ? 2 : 3 };
				Post(updateUrl, op)
					.then((resp) => {
						toast("Status updated");
					})
					.catch((err) => {
						Error(err);
					});
				onChange(op);
			});
		return;
	};

	const confirmOption = {
		title: name,
		text: "You are about to update the milestone status",
		options: group,
		exitText: "Exit",
		okText: "Continue",
		onOk: send,
	};
	const isExecutor = hasRole(executor.name.toLowerCase()) && status.id === 1;
	const isOperator = hasRole("operation") && status.id === 2;
	const isEnterpriseOperator = hasRole("enterprise-ops") && status.id === 2;
	const isEnterprise =
		hasRole("enterprise") &&
		executor.name.toLowerCase().localeCompare("engineer") === 0 &&
		status.id === 1;
	const isAdmin = hasRole("admin");
	const onClick = (state) => {
		if (!state) {
			return;
		}
		if (
			isEnterprise ||
			isOperator ||
			isEnterpriseOperator ||
			isExecutor ||
			isAdmin
		) {
			ModalAction.confirm(confirmOption);
		} else if (status.id === 1) {
			toast("This operation is reserved for " + executor.name);
		} else {
			toast("This function is reserved for Operation");
		}
	};
	return (
		<div className={style.Milestone}>
			<div>
				<RoundedStatus onClick={onClick} status={status.id}></RoundedStatus>
			</div>
			<div className={style.Title}>
				{name}
				<span className={style.count}>{group.length}</span>
			</div>
		</div>
	);
};
export default Milestone;
