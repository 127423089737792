import React, { useState, useEffect } from "react";
import logoImg from "../../Assets/Images/Logo2.png";
import User from "../../Services/User";
import NavItem from "./NavItem";
import NavToggle from "./NavToggle";
import { hasMaritimeAccess, hasEnterpriseAccess } from "../../Data/RoleAccess";
import "./NavMenu.css";

const setNav = { notification: null };

const NavMenu = ({ children, accNav, logout }) => {
	const base = User.getBase();
	const roles = User.getRoles().map((role) => role.name);

	const maritimeAccess = hasMaritimeAccess(roles);
	const enterpriseAccess = hasEnterpriseAccess(roles);

	const initialCategory =
		localStorage.getItem("selectedCategory") ||
		(maritimeAccess ? "maritime" : "enterprise");
	const [selectedCategory, setSelectedCategory] = useState(initialCategory);

	useEffect(() => {
		if (!maritimeAccess && enterpriseAccess) {
			setSelectedCategory("enterprise");
		} else if (!enterpriseAccess && maritimeAccess) {
			setSelectedCategory("maritime");
		}
	}, [maritimeAccess, enterpriseAccess]);

	const handleCategoryChange = (category) => {
		setSelectedCategory(category);
		localStorage.setItem("selectedCategory", category);
	};

	const auNavOptions = children || [];
	const filteredNavOptions = auNavOptions.filter(
		(item) => item.category === selectedCategory
	);

	const [notifyOption, setNotify] = useState(accNav[0]);
	const accNavOptions = accNav.slice(1);

	setNav.notification = (num) => {
		setNotify({ ...notifyOption, notify: num + "" });
	};

	return (
		<div className="NavMenu">
			<div className="logoCont">
				<img src={logoImg} alt="Logo" />
			</div>
			<div>
				{maritimeAccess && enterpriseAccess ? (
					<NavToggle
						selectedCategory={selectedCategory}
						setSelectedCategory={handleCategoryChange}
					/>
				) : (
					<div className="NavTitle">
						<h5>
							{selectedCategory === "maritime" ? "Maritime" : "Enterprise"}
						</h5>
					</div>
				)}
				<div className="NavItems">
					{filteredNavOptions.map((navoption, index) => (
						<NavItem base={base} key={index}>
							{navoption}
						</NavItem>
					))}
				</div>
			</div>
			{accNav && (
				<div>
					<div className="NavTitle tm40">
						<h5>Account</h5>
					</div>
					<div className="NavItems">
						<NavItem base={base}>{notifyOption}</NavItem>
						{accNavOptions.map((navoption, index) => (
							<NavItem base={base} key={index}>
								{navoption}
							</NavItem>
						))}
					</div>
				</div>
			)}
			<div className="Logout">
				{logout && (
					<div className="NavItems">
						<NavItem>{logout}</NavItem>
					</div>
				)}
			</div>
		</div>
	);
};

export default NavMenu;
export { setNav };
