import React, { useState } from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import fblogo from "../../../Assets/Images/Logo2.png";
import styles from "./Style";
import parse from "html-react-parser";

const ContractDocument = ({ job, sign }) => {
	const { initial } = job;
	const {
		billingInformation,
		subscriberInformation,
		clauses,
		contactsInformationList,
	} = initial.contract;
	const contact = contactsInformationList[0];
	const date = new Date(sign?.time);
	const ucFirst = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const renderHtmlContent = (html) => {
		html = html.replace(/class="[^"]*"/g, "");

		return parse(html, {
			replace: (domNode) => {
				if (domNode.name === "strong") {
					return (
						<Text style={styles.bold}>
							{domNode.children.map((child) => child.data).join("")}
						</Text>
					);
				}
				if (domNode.name === "u") {
					return (
						<Text style={styles.underline}>
							{domNode.children.map((child) => child.data).join("")}
						</Text>
					);
				}
				if (domNode.name === "em") {
					return (
						<Text style={styles.italic}>
							{domNode.children.map((child) => child.data).join("")}
						</Text>
					);
				}
				if (domNode.name === "p") {
					return (
						<Text style={styles.paragraph}>
							{domNode.children.map((child) => child.data).join("")}
						</Text>
					);
				}
				return (
					<Text>
						{domNode.data ||
							domNode.children.map((child) => child.data).join("")}
					</Text>
				);
			},
		});
	};

	return (
		<Document>
			<Page style={styles.page}>
				<View style={styles.header}>
					<Image src={fblogo} style={styles.logo} />
					<View style={styles.address}>
						<Text>CBC Towers, 10/11 Olubunmi Owa street,</Text>
						<Text>Lekki Phase 1, Lagos, Nigeria</Text>
						<Text>Mob: 0201 2278 181</Text>
						<Text>E-mail: support@fbase.co.uk</Text>
						<Text>Web: www.fbase.co.uk</Text>
					</View>
				</View>

				{/* Contract Agreement Title */}
				<Text style={styles.contractTitle}> SERVICE CONTRACT</Text>

				{/* Service Provider Information */}
				<View style={styles.section}>
					<Text style={styles.sectionTitle}>
						Service Provider's Information
					</Text>
					<View style={styles.infoGroup}>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Company:</Text>
								<Text style={styles.text}>Fieldbase Services Limited</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Email:</Text>
								<Text style={styles.text}>sales@fbase.co.uk</Text>
							</View>
						</View>

						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Company Number:</Text>
								<Text style={styles.text}>F10637893</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Phone:</Text>
								<Text style={styles.text}>07425-155-321</Text>
							</View>
						</View>

						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>VAT Number:</Text>
								<Text style={styles.text}>313 7483 09</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Country:</Text>
								<Text style={styles.text}>United Kingdom</Text>
							</View>
						</View>

						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Contact First Name:</Text>
								<Text style={styles.text}>Chinedu</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Contact Last Name:</Text>
								<Text style={styles.text}>Abanobi</Text>
							</View>
						</View>

						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Role:</Text>
								<Text style={styles.text}>Managing Director</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>City:</Text>
								<Text style={styles.text}>London</Text>
							</View>
						</View>

						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Province:</Text>
								<Text style={styles.text}>Greater London</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Postal Code:</Text>
								<Text style={styles.text}>EC3V 3NG</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Address:</Text>
								<Text style={styles.text}>
									International House, 36-38 Cornhill
								</Text>
							</View>
							<View style={styles.items}></View>
						</View>
					</View>
				</View>

				{/* Subscriber's Information */}
				<View style={styles.section}>
					<Text style={styles.sectionTitle}>Subscriber's Information</Text>
					<View style={styles.infoGroup}>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Account Type:</Text>
								<Text style={styles.text}>
									{ucFirst(subscriberInformation?.accountType || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Email:</Text>
								<Text style={styles.text}>{ucFirst(job?.email || "-")}</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Company:</Text>
								<Text style={styles.text}>
									{ucFirst(initial?.company?.name || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Phone:</Text>
								<Text style={styles.text}>{ucFirst(job?.phone || "-")}</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Country:</Text>
								<Text style={styles.text}>
									{ucFirst(subscriberInformation?.country || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>First Name:</Text>
								<Text style={styles.text}>
									{ucFirst(job?.firstname || "-")}
								</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>State:</Text>
								<Text style={styles.text}>
									{ucFirst(subscriberInformation?.state || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Last Name:</Text>
								<Text style={styles.text}>{ucFirst(job?.lastname || "-")}</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Role:</Text>
								<Text style={styles.text}>
									{ucFirst(subscriberInformation?.role || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Also Designated as:</Text>
								<Text style={styles.text}>
									{ucFirst(subscriberInformation?.designation || "-")}
								</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Postal Code:</Text>
								<Text style={styles.text}>
									{subscriberInformation?.postalCode?.toUpperCase() || "-"}
								</Text>
							</View>

							{initial.vessel?.name ? (
								<View style={styles.items}>
									<Text style={styles.key}>Vessel Name:</Text>
									<Text style={styles.text}>
										{ucFirst(initial?.vessel?.name || "-")}
									</Text>
								</View>
							) : (
								<View></View>
							)}
						</View>
					</View>
				</View>

				{/* Billing Information */}
				<View style={styles.section}>
					<Text style={styles.sectionTitle}>Billing Information</Text>
					<View style={styles.infoGroup}>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Account Type:</Text>
								<Text style={styles.text}>-</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Email:</Text>
								<Text style={styles.text}>
									{billingInformation?.email || "-"}
								</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Company:</Text>
								<Text style={styles.text}>
									{ucFirst(billingInformation?.company?.name || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Phone:</Text>
								<Text style={styles.text}>
									{billingInformation?.phone || "-"}
								</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>Postal Code:</Text>
								<Text style={styles.text}>
									{billingInformation?.postalCode?.toUpperCase() || "-"}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Country:</Text>
								<Text style={styles.text}>
									{ucFirst(billingInformation?.country || "-")}
								</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>First Name:</Text>
								<Text style={styles.text}>
									{ucFirst(billingInformation?.firstname || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Last Name:</Text>
								<Text style={styles.text}>
									{ucFirst(billingInformation?.lastname || "-")}
								</Text>
							</View>
						</View>
						<View style={styles.infoRow}>
							<View style={styles.items}>
								<Text style={styles.key}>State:</Text>
								<Text style={styles.text}>
									{ucFirst(billingInformation?.state || "-")}
								</Text>
							</View>
							<View style={styles.items}>
								<Text style={styles.key}>Address:</Text>
								<Text style={styles.text}>
									{ucFirst(billingInformation?.address || "-")}
								</Text>
							</View>
						</View>
					</View>
				</View>
			</Page>
			<Page style={styles.page}>
				{/* Terms & Conditions */}
				<View style={styles.section}>
					<Text style={styles.sectionTitle}>Terms & Conditions</Text>
					{clauses.map((clause) => (
						<View key={clause.id} style={styles.clause}>
							<Text style={styles.clauseTitle}>{clause.name}</Text>
							{renderHtmlContent(clause.details)}
						</View>
					))}
				</View>

				{/* Signature Section */}
				<Text style={styles.sectionTitle}>Signature</Text>

				<View style={styles.signatureSection}>
					<View>
						<Text style={styles.text2}>Chinedu Abanobi</Text>
						<Text style={styles.text2}>Managing Director at Fieldbase</Text>
						<Image src="/signature.png" style={styles.signatureImage} />
						<Text style={styles.dateText}> 12 Jan 2024</Text>
					</View>
					<View>
						<Text style={styles.text2}>
							{ucFirst(job.firstname)} {ucFirst(job.lastname)}
						</Text>
						<Text style={styles.text2}>
							{subscriberInformation?.designation?.name}
						</Text>
						<Image src={sign?.data} style={styles.signatureImage} />
						<Text style={styles.dateText}>
							{date?.toDateString()} {date?.toLocaleTimeString()}
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default ContractDocument;
