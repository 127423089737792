import React from "react";
import css from "./style.module.css";
import Button from "../../../Components/Button/Button";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import { useState } from "react";
import { Post } from "../../../Services/Server";
import Url from "../../../Services/Url";
import { toast } from "react-toastify";
const MsNotify = ({ children }) => {
	const [msGroup, setGroup] = useState(children);
	const setView = (m) => {
		m.clientView = !m.clientView;
		setGroup({ ...msGroup });

		Post(Url.saveTaskMilestone, m);
		toast("Milestone Updated");
	};
	return (
		<div className={css.MsNotify}>
			<div className={css.Name}>{msGroup.name}</div>
			<div className={css.MsEquipments}>
				{msGroup.group.map((m) => (
					<div key={m.id}>
						<Button
							onClick={() => setView(m)}
							icon={m.clientView ? SvgOptions.Bell2 : SvgOptions.BellOff}
							className={"Btn-Mid" + (m.clientView ? " Active" : "")}
						>
							{m.equipment}
						</Button>
					</div>
				))}
			</div>
		</div>
	);
};

export default MsNotify;
